import * as THREE from 'three';

import { EphemPresets } from '../data/ephem-presets';
import { EOrbitalType } from '../models/EOrbitalType';
import { IXYZ } from '../models/IXYZ';
import { TPlanets } from '../models/TPlanets';
import { auToMeters } from './conversions';
import { SKEphem } from './sk-ephem';
import { SKOrbit } from './sk-orbit';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { getLoggedPosition } from './get-logged-position';
import { planetoidPositions } from './planetoid-positions-all';
import { getLocalOrbitData } from './get-local-orbit-data';

const au = auToMeters(1);

export class MyOrbit {
  // --->>>

  private orbitLine!: THREE.Line<
    THREE.BufferGeometry,
    THREE.LineBasicMaterial
  >;

  constructor(
    private name: string,
    private orbitalType = EOrbitalType.PLANET,
    private color: string = 'white',
    private opacity: number = 0.5
  ) {
    //
    switch (this.orbitalType) {
      case EOrbitalType.ASTEROID: {
        this.loadPlanet();
        break;
      }
      case EOrbitalType.PLANET: {
        this.loadPlanet();
        break;
      }
      case EOrbitalType.DWARF_PLANET: {
        this.loadPlanet();
        break;
      }
      default: {
        this.loadPlanet();
        break;
      }
    }
  }

  loadPlanet = () => {
    //
    this.getOrbitLine();
  };

  getStaticPosition() {
    const planet = planetoidPositions.find(
      planetoidPosition =>
        planetoidPosition.name.toLowerCase() ===
        this.name.toLowerCase()
    );
    if (!planet) {
      throw Error(`No planet found for ${this.name}`);
    }
    planet.coords;
  }

  getOrbitLine() {
    if (!!this.orbitLine) return this.orbitLine;

    const data = getLocalOrbitData(this.name);
    const pts: THREE.Vector3[] = [];
    data.coords.forEach((coord: number[]) => {
      const f = 1;
      pts.push(
        new THREE.Vector3(
          auToMeters(coord[0] * f),
          auToMeters(coord[1] * f),
          auToMeters(coord[2] * f)
        )
      );
    });
    const pointGeometry = new THREE.BufferGeometry();
    pointGeometry.setFromPoints(pts);

    pointGeometry.morphAttributes.position = [];
    const positionAttribute =
      pointGeometry.attributes.position;
    const loggedPositions = [];
    for (let i = 0; i < positionAttribute.count; i++) {
      const f = 1;
      const x0 = positionAttribute.getX(i) * f;
      const y0 = positionAttribute.getY(i) * f;
      const z0 = positionAttribute.getZ(i) * f;

      const position = new THREE.Vector3(x0, y0, z0);
      const loggedPosition = getLoggedPosition(position);
      const { x, y, z } = loggedPosition;
      loggedPositions.push(x, y, z);
    }
    pointGeometry.morphAttributes.position[0] = new THREE.Float32BufferAttribute(
      loggedPositions,
      3
    );

    this.orbitLine = new THREE.Line(
      pointGeometry,
      new THREE.LineBasicMaterial({
        color: new THREE.Color(this.color),
        morphTargets: true,
        opacity: this.opacity,
        transparent: true,
      })
    );

    if (false) {
      this.orbitLine = new THREE.LineSegments(
        pointGeometry,
        new THREE.LineBasicMaterial({
          color: new THREE.Color(this.color),
          morphTargets: true,
        })
      );
    }

    return this.orbitLine;
  }

  // getProjectedOrbitLine = () => this.SKprojectedOrbitLine;

  getXyzMeters(tCenturiesSinceJ200 = 0): IXYZ {
    // --->>>

    const position = this.orbitLine.geometry.attributes
      .position;
    const x = position.getX(0);
    const y = position.getY(0);
    const z = position.getZ(0);
    return { x, y, z };
  }

  getPosition(tCenturiesSinceJ200 = 0) {
    const { x, y, z } = this.getXyzMeters(
      tCenturiesSinceJ200
    );
    return new THREE.Vector3(x, y, z);
  }

  getTail(
    radius: number,
    tailLength = au * 0.3,
    tCenturiesSinceJ200 = 0
  ) {
    // --->>

    // Calc positions
    const realBodyPosition = this.getPosition(
      tCenturiesSinceJ200
    );
    const loggedBodyPosition = getLoggedPosition(
      realBodyPosition
    );

    // Compute times for real and logged positions to stretch out to tailLength
    let realTargetTime = tCenturiesSinceJ200;
    let loggedTargetTime = tCenturiesSinceJ200;
    let realDiffLength = 0;
    let loggedDiffLength = 0;
    let isRealSearch = true;
    let isLoggedSearch = true;
    const d = new Date();
    while (isRealSearch || isLoggedSearch) {
      // --->

      isRealSearch = realDiffLength < tailLength;
      isLoggedSearch = loggedDiffLength < tailLength;
      if (isRealSearch) {
        realTargetTime += 0.5 * 10;
        realDiffLength = realBodyPosition.distanceTo(
          this.getPosition(realTargetTime)
        );
      }
      if (isLoggedSearch) {
        loggedTargetTime += 0.5 * 10;
        loggedDiffLength = loggedBodyPosition.distanceTo(
          getLoggedPosition(
            this.getPosition(loggedTargetTime)
          )
        );
      }
    }
    // console.log(' 6>>> ', +new Date() - +d, ' >>> ');

    // Set up loop to generate segments
    const radialSegments = 3;
    const heightSegments = 1;
    const numberOfSegments = 5;
    const realDt =
      (realTargetTime - tCenturiesSinceJ200) /
      numberOfSegments;
    const loggedDt =
      (loggedTargetTime - tCenturiesSinceJ200) /
      numberOfSegments;
    const dSegmentRadius = radius / numberOfSegments;
    const geometries: {
      realSegmentGeometry: THREE.BufferGeometry;
      loggedSegmentGeometry: THREE.BufferGeometry;
    }[] = [];
    let lastRealPosition = realBodyPosition.clone();
    let lastLoggedPosition = loggedBodyPosition.clone();
    let nextRealPosition = realBodyPosition.clone();
    let nextLoggedPosition = loggedBodyPosition.clone();
    let lastSegmentRadius = radius;
    let i = 0;

    // Generate segments
    for (
      let segment = 1;
      segment <= numberOfSegments;
      segment++
    ) {
      // Extrapolate back in time to compute positions of tail
      const tReal = tCenturiesSinceJ200 + segment * realDt;
      const tLogged =
        tCenturiesSinceJ200 + segment * loggedDt;
      nextRealPosition = this.getPosition(tReal);
      nextLoggedPosition = getLoggedPosition(
        this.getPosition(tLogged)
      );

      // Compute radius of the end of this segment
      let nextSegmentRadius =
        lastSegmentRadius - dSegmentRadius;

      // Compute height of segment
      const realSegmentHeight = lastRealPosition.distanceTo(
        nextRealPosition
      );
      const loggedSegmentHeight = lastLoggedPosition.distanceTo(
        nextLoggedPosition
      );

      // Create tail segments at coord origin
      const realSegmentGeometry = new THREE.CylinderGeometry(
        lastSegmentRadius,
        nextSegmentRadius,
        realSegmentHeight,
        radialSegments,
        heightSegments,
        !true
      );
      const loggedSegmentGeometry = new THREE.CylinderGeometry(
        lastSegmentRadius,
        nextSegmentRadius,
        loggedSegmentHeight,
        radialSegments,
        heightSegments,
        !true
      );

      // Position and rotate geometry
      {
        const { x, y, z } = lastRealPosition;
        const orientation = new THREE.Matrix4();
        orientation.makeTranslation(x, y, z);
        orientation.lookAt(
          lastRealPosition,
          nextRealPosition,
          new THREE.Vector3(0, 0, 1)
        );
        realSegmentGeometry.translate(
          0,
          -realSegmentHeight / 2,
          0
        ); // Rotate around end
        realSegmentGeometry.rotateX(Math.PI / 2);
        realSegmentGeometry.applyMatrix4(orientation);
      }
      {
        const { x, y, z } = lastLoggedPosition;
        const orientation = new THREE.Matrix4();
        orientation.makeTranslation(x, y, z);
        orientation.lookAt(
          lastLoggedPosition,
          nextLoggedPosition,
          new THREE.Vector3(0, 0, 1)
        );
        loggedSegmentGeometry.translate(
          0,
          -loggedSegmentHeight / 2,
          0
        ); // Rotate around end
        loggedSegmentGeometry.rotateX(Math.PI / 2);
        loggedSegmentGeometry.applyMatrix4(orientation);
      }

      // Store segments
      geometries.push({
        realSegmentGeometry,
        loggedSegmentGeometry,
      });

      // End loop
      lastRealPosition = nextRealPosition;
      lastLoggedPosition = nextLoggedPosition;
      lastSegmentRadius = nextSegmentRadius;
    }

    // Merge segments into single geometry
    const realGeometry = BufferGeometryUtils.mergeBufferGeometries(
      geometries.map(el => el.realSegmentGeometry),
      true
    );
    const loggedGeometry = BufferGeometryUtils.mergeBufferGeometries(
      geometries.map(el => el.loggedSegmentGeometry),
      true
    );

    return { realGeometry, loggedGeometry };
  }
}
