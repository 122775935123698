
export const planetoidPositions = [
  {
    "targetName": "sun",
    "name": "Sun",
    "coords": [
      [
        25210928.558993563,
        -144927919.5838006,
        616.4165719002485
      ]
    ]
  },
  {
    "targetName": "mercury",
    "name": "Mercury",
    "coords": [
      [
        4158307.486552868,
        -211334557.7063349,
        -3491829.530005828
      ]
    ]
  },
  {
    "targetName": "venus",
    "name": "Venus",
    "coords": [
      [
        -82294621.71299145,
        -148294440.250323,
        6159836.205810949
      ]
    ]
  },
  {
    "targetName": "earth",
    "name": "Earth",
    "coords": [
      [
        0,
        0,
        0
      ]
    ]
  },
  {
    "targetName": "moon",
    "name": "Moon",
    "coords": [
      [
        -317650.2412950324,
        -241883.1762389348,
        36555.82307692753
      ]
    ]
  },
  {
    "targetName": "mars",
    "name": "Mars",
    "coords": [
      [
        233205983.5426107,
        -148070929.2977431,
        -5178164.826916882
      ]
    ]
  },
  {
    "targetName": "phobos",
    "name": "Phobos",
    "coords": [
      [
        233211101.3822698,
        -148063549.26654398,
        -5180330.081629686
      ]
    ]
  },
  {
    "targetName": "deimos",
    "name": "Deimos",
    "coords": [
      [
        233186774.50707632,
        -148062438.7574959,
        -5167707.330730483
      ]
    ]
  },
  {
    "targetName": "jupiter",
    "name": "Jupiter",
    "coords": [
      [
        624120093.099128,
        294194667.0766834,
        -15231894.21368285
      ]
    ]
  },
  {
    "targetName": "io",
    "name": "Io",
    "coords": [
      [
        624164885.3477856,
        293774388.4102297,
        -15246145.23852706
      ]
    ]
  },
  {
    "targetName": "europa",
    "name": "Europa",
    "coords": [
      [
        623488011.4500723,
        294412338.4072513,
        -15234937.06745064
      ]
    ]
  },
  {
    "targetName": "ganymede",
    "name": "Ganymede",
    "coords": [
      [
        623084362.3434044,
        293923020.97313803,
        -15253539.800429901
      ]
    ]
  },
  {
    "targetName": "callisto",
    "name": "Callisto",
    "coords": [
      [
        624786088.0070852,
        295950532.9487813,
        -15165777.127540601
      ]
    ]
  },
  {
    "targetName": "saturn",
    "name": "Saturn",
    "coords": [
      [
        983917265.7323134,
        837637290.875011,
        -55220039.89568457
      ]
    ]
  },
  {
    "targetName": "mimas",
    "name": "Mimas",
    "coords": [
      [
        983804295.554162,
        837775268.2467614,
        -55281673.06765947
      ]
    ]
  },
  {
    "targetName": "enceladus",
    "name": "Enceladus",
    "coords": [
      [
        983679565.3746865,
        837641881.7164687,
        -55199377.11102869
      ]
    ]
  },
  {
    "targetName": "tethys",
    "name": "Tethys",
    "coords": [
      [
        983700604.5912714,
        837469073.320275,
        -55112213.72623366
      ]
    ]
  },
  {
    "targetName": "dione",
    "name": "Dione",
    "coords": [
      [
        983738562.4193845,
        837350098.2216039,
        -55052252.21436214
      ]
    ]
  },
  {
    "targetName": "rhea",
    "name": "Rhea",
    "coords": [
      [
        983496804.0029027,
        837934991.242741,
        -55333843.04381296
      ]
    ]
  },
  {
    "targetName": "titan",
    "name": "Titan",
    "coords": [
      [
        983140311.5462098,
        838539854.7562562,
        -55609705.82393074
      ]
    ]
  },
  {
    "targetName": "hyperion",
    "name": "Hyperion",
    "coords": [
      [
        984302160.0114294,
        838839267.3741561,
        -55865294.29589018
      ]
    ]
  },
  {
    "targetName": "iapetus",
    "name": "Iapetus",
    "coords": [
      [
        980982613.8435904,
        835728072.4766542,
        -54179243.405296244
      ]
    ]
  },
  {
    "targetName": "phoebe",
    "name": "Phoebe",
    "coords": [
      [
        972213960.2836474,
        834830558.7102093,
        -53885687.66340354
      ]
    ]
  },
  {
    "targetName": "uranus",
    "name": "Uranus",
    "coords": [
      [
        2183985628.2833414,
        -2199753070.769538,
        -35622995.26408243
      ]
    ]
  },
  {
    "targetName": "miranda",
    "name": "Miranda",
    "coords": [
      [
        2184101946.303993,
        -2199757607.225512,
        -35471698.959801205
      ]
    ]
  },
  {
    "targetName": "ariel",
    "name": "Ariel",
    "coords": [
      [
        2184223622.94344,
        -2199819140.7233667,
        -35723726.65271461
      ]
    ]
  },
  {
    "targetName": "umbriel",
    "name": "Umbriel",
    "coords": [
      [
        2184075460.2902603,
        -2199831716.226388,
        -36041929.76960051
      ]
    ]
  },
  {
    "targetName": "titania",
    "name": "Titania",
    "coords": [
      [
        2183464821.607966,
        -2199675659.639669,
        -35874478.44673729
      ]
    ]
  },
  {
    "targetName": "oberon",
    "name": "Oberon",
    "coords": [
      [
        2184108809.161395,
        -2199774688.69416,
        -35657554.89030111
      ]
    ]
  },
  {
    "targetName": "neptune",
    "name": "Neptune",
    "coords": [
      [
        2540064210.9294276,
        -3883775334.299313,
        19040215.18757224
      ]
    ]
  },
  {
    "targetName": "triton",
    "name": "Triton",
    "coords": [
      [
        2539764375.3450627,
        -3883795716.454041,
        19228100.00823021
      ]
    ]
  },
  {
    "targetName": "nereid",
    "name": "Nereid",
    "coords": [
      [
        2540979035.75896,
        -3874452071.9913187,
        19721327.09596634
      ]
    ]
  },
  {
    "targetName": "pluto",
    "name": "Pluto",
    "coords": [
      [
        -1452347411.189161,
        -4327388362.774305,
        875270025.2620398
      ]
    ]
  },
  {
    "targetName": "charon",
    "name": "Charon",
    "coords": [
      [
        -1452347453.345824,
        -4327398762.531079,
        875253416.4866599
      ]
    ]
  },
  {
    "targetName": "nix",
    "name": "Nix",
    "coords": [
      [
        -1452354210.566489,
        -4327369571.663404,
        875312072.117779
      ]
    ]
  },
  {
    "targetName": "hydra",
    "name": "Hydra",
    "coords": [
      [
        -1452312605.147671,
        -4327384745.736898,
        875214226.638797
      ]
    ]
  },
  {
    "targetName": "kerberos",
    "name": "Kerberos",
    "coords": [
      [
        -1452336967.884832,
        -4327411470.959048,
        875215628.484605
      ]
    ]
  },
  {
    "targetName": "styx",
    "name": "Styx",
    "coords": [
      [
        -1452316434.820314,
        -4327363528.824732,
        875256065.2688253
      ]
    ]
  },
  {
    "targetName": "ceres",
    "name": "Ceres",
    "coords": [
      [
        -330462529.1598387,
        -25133356.299232878,
        69240119.8850444
      ]
    ]
  },
  {
    "targetName": "vesta",
    "name": "Vesta",
    "coords": [
      [
        -178000989.05262348,
        -394670970.71612394,
        32220090.03596649
      ]
    ]
  },
  {
    "targetName": "haumea",
    "name": "Haumea",
    "coords": [
      [
        -6854796958.419188,
        -910784984.6918347,
        3348814291.8124943
      ]
    ]
  }
]