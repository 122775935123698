import * as THREE from 'three';

import { EOrbitalType } from '../models/EOrbitalType';
import { Orbit } from '../utils/orbit';
import { AbstractToyModel } from '../abstract-scene/abstract-toy-model';
import { createAsteroidGeometry } from '../utils/create-asteroid-geometry';
import { getTextureFromImageUrl } from '../utils/get-texture-from-image-url';
import { assetsBaseUrl } from '../utils/constants';
import { IZoomableOrbital } from '../models/IZoomableOrbital';
import { getLoggedPosition } from '../utils/get-logged-position';
import { MyOrbit } from '../utils/myorbit';

const defaultRadiusMeters = 10000;

export class Asteroid extends AbstractToyModel
  implements IZoomableOrbital {
  // --->>>

  private model = new THREE.Group();
  private myOrbit: MyOrbit;

  constructor(
    public readonly NAME: string,
    private radius = defaultRadiusMeters
  ) {
    super(300 * 100);
    this.myOrbit = new MyOrbit(
      NAME,
      EOrbitalType.ASTEROID,
      'red',
      1
    );
    this._sceneEntityGroup.add(this.myOrbit.getOrbitLine());
  }

  async init() {
    return new Promise<THREE.Group>(async resolve => {
      // --->>>

      const url = `${assetsBaseUrl}/misc/asteroid-texture-1024.jpg`;

      const geometry = createAsteroidGeometry(this.radius);
      const mesh = new THREE.Mesh(
        geometry.realGeometry,
        new THREE.MeshPhongMaterial({
          // color: new THREE.Color('red'),
          map: await getTextureFromImageUrl(url),
          shininess: 0,
        })
      );
      this.model.add(mesh);
      this._toyGroup.push(this.model);
      this._sceneEntityGroup.add(this.model);

      resolve(this._sceneEntityGroup);
    });
  }

  public getPosition = () => {
    const { x, y, z } = this.myOrbit.getXyzMeters();
    return new THREE.Vector3(x, y, z);
  };

  public getRadius = () => this.radius;

  public getOrbit = () => this.myOrbit;

  public setIsOrbitVisible = (val: boolean) => {
    this.myOrbit.getOrbitLine().visible = val;
  };

  // Gets position of planet in either normal or logged coords
  getDestinationPosition(_tCenturiesSinceJ200 = 0) {
    const u = this.getLogInterpolationParam();
    const position = this.myOrbit.getPosition(
      _tCenturiesSinceJ200
    );
    const logpos = getLoggedPosition(position);
    return position.lerp(logpos, u);
  }

  updateOrbitLine() {
    const u = this.getLogInterpolationParam();
    this.myOrbit.getOrbitLine().morphTargetInfluences![0] = u;
  }

  update() {
    const { x, y, z } = this.getDestinationPosition();
    this.model.position.set(x, y, z);

    this.updateOrbitLine();

    // Toy Model Scale
    this._updateModelScale();
  }
}
