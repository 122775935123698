//
import * as mercuryOrbit from '../data/orbits/planetoid/mercury-orbit.json';
import * as venusOrbit from '../data/orbits/planetoid/venus-orbit.json';
import * as earthOrbit from '../data/orbits/planetoid/earth-orbit.json';
import * as marsOrbit from '../data/orbits/planetoid/mars-orbit.json';
import * as jupiterOrbit from '../data/orbits/planetoid/jupiter-orbit.json';
import * as saturnOrbit from '../data/orbits/planetoid/saturn-orbit.json';
import * as uranusOrbit from '../data/orbits/planetoid/uranus-orbit.json';
import * as neptuneOrbit from '../data/orbits/planetoid/neptune-orbit.json';
//
import * as arielOrbit from '../data/orbits/planetoid/ariel-orbit.json';
import * as callistoOrbit from '../data/orbits/planetoid/callisto-orbit.json';
import * as ceresOrbit from '../data/orbits/planetoid/ceres-orbit.json';
import * as plutoOrbit from '../data/orbits/planetoid/pluto-orbit.json';
import * as haumeaOrbit from '../data/orbits/planetoid/haumea-orbit.json';

//
import * as comet65POrbit from '../data/orbits/comet/65P-orbit.json';
// import * as comet65POrbit from '../data/orbits-30/comet/65P-orbit.json';

export function getLocalOrbitData(name: string) {
  //
  switch (name.toUpperCase()) {
    case 'MERCURY': {
      return mercuryOrbit;
    }
    case 'VENUS': {
      return venusOrbit;
    }
    case 'EARTH': {
      return earthOrbit;
    }
    case 'MARS': {
      return marsOrbit;
    }
    case 'JUPITER': {
      return jupiterOrbit;
    }
    case 'SATURN': {
      return saturnOrbit;
    }
    case 'URANUS': {
      return uranusOrbit;
    }
    case 'NEPTUNE': {
      return neptuneOrbit;
    }
    // DWARF PLANETS: Alphabetic order
    case 'ARIAL': {
      return arielOrbit;
    }
    case 'CALLISTO': {
      return callistoOrbit;
    }
    case 'CERES': {
      return ceresOrbit;
    }
    case 'PLUTO': {
      return plutoOrbit;
    }
    case 'HAUMEA': {
      return haumeaOrbit;
    }
    //
    case '65P': {
      return comet65POrbit;
    }
    default: {
      throw new Error(`No local orbit found for ${name}`);
      return earthOrbit;
    }
  }
}
